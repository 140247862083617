<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="xl"
  >
    <template #modal-header>
      <h5>{{ title }}</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypedocumentForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Tipo de documento"
                    label-for="typedocument-descripcion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Tipo de documento"
                    >
                      <v-select
                        v-model="id_tipo_documento"
                        name="typedocument-tipo-estudio-id"
                        label="name"
                        :options="rowsTypeDocument"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Documento de estudiante"
                    label-for="typedocument-is-active"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="ruleFile"
                      name="Documento de estudiante"
                    >
                      <b-form-file
                        ref="file-input"
                        v-model="file"
                        class="mb-2"
                        placeholder="Selecciona un archivo"
                        accept="application/pdf"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <iframe
                    v-if="file || getFilePath"
                    height="400px"
                    width="100%"
                    :src="`${getFilePath}`"
                  />
                </b-col>
                <b-col
                  v-if="is_translator"
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Documento de Traducido"
                    label-for="typedocument-is-active"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="ext:pdf"
                      name="Documento de Traducido"
                    >
                      <b-form-file
                        ref="file-input"
                        v-model="fileTraducido"
                        class="mb-2"
                        placeholder="Selecciona un archivo"
                        accept="application/pdf"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <iframe
                    v-if="fileTraducido || getFilePathTraducido"
                    height="400px"
                    width="100%"
                    :src="`${getFilePathTraducido}`"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, ext,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BForm, BButton, BContainer, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: true,
      default: () => null,
    },
    userID: {
      type: Number,
      required: true,
      default: () => 0,
    },
    paisID: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      idDocumento: null,
      idDocumentoTraducido: null,
      idDocumentoExample: null,
      id_tipo_documento: null,
      user_id: null,
      value: [],
      options: [],
      rowsTypeDocument: [],
      file: null,
      fileTraducido: null,
      getFilePath: null,
      getFilePathTraducido: null,
      ruleFile: 'required|ext:pdf',
      is_translator: 0,
      // validation rules
      required,
      ext,
    }
  },
  computed: {
    title() {
      return this.infoData ? 'Editar' : 'Crear'
    },
  },
  watch: {
    file(val) {
      if (val != null) {
        this.getFilePath = URL.createObjectURL(val)
      }
    },
    fileTraducido(val) {
      if (val != null) {
        this.getFilePathTraducido = URL.createObjectURL(val)
      }
    },
  },
  created() {
    this.getTipoDocumento()
    this.setInfoData()
  },
  methods: {
    setInfoData() {
      this.clear()
      if (Object.keys(this.infoData).length > 0) {
        this.idDocumento = this.infoData.id
        if (this.infoData.documento_example && this.infoData.documento_example.is_translator) {
          this.is_translator = this.infoData.documento_example.is_translator
        }
        if (this.infoData.documento_traducido != null) {
          this.idDocumentoTraducido = this.infoData.documento_traducido.id
        }
        if (this.infoData.documento_traducido != null) {
          this.idDocumentoExample = this.infoData.documento_traducido.id
        }
        if (this.infoData.documento_example != null) {
          this.id_tipo_documento = this.infoData.documento_example
        }
        if (this.infoData.archivo != null) {
          this.getFilePath = `${process.env.VUE_APP_URL_API}/document_users/${this.infoData.archivo}`
        }
        if (this.infoData.documento_traducido != null) {
          this.getFilePathTraducido = `${process.env.VUE_APP_URL_API}/document_users/${this.infoData.documento_traducido.archivo}`
        }
        this.ruleFile = ''
      }
    },
    close() {
      this.$emit('close')
    },
    clear() {
      this.idDocumento = null
      this.idDocumentoTraducido = null
      this.idDocumentoExample = null
      this.id_tipo_documento = null
      this.file = null
      this.fileTraducido = null
      this.getFilePath = null
      this.getFilePathTraducido = null
      this.ruleFile = 'required|ext:pdf'
    },
    getTipoDocumento() {
      this.isLoading = true
      this.$http.get(`/v1/type-document/get/type/${this.paisID}`)
        .then(res => {
          this.isLoading = false
          this.rowsTypeDocument = res.data
        })
    },
    save() {
      this.$refs.TypedocumentForm.validate().then(success => {
        if (success) {
          if (Object.keys(this.infoData).length > 0) {
            const data = new FormData()
            data.append('idDocumento', this.idDocumento)
            data.append('idDocumentoTraducido', this.idDocumentoTraducido)
            data.append('idDocumentoExample', this.idDocumentoExample)
            data.append('id_tipo_documento', JSON.stringify(this.id_tipo_documento))
            data.append('user_id', this.userID)
            data.append('archivo', this.file)
            data.append('archivoTraducido', this.fileTraducido)
            this.$http.put(`/v1/document-studiante/update/`, data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            const data = new FormData()
            data.append('idDocumentoExample', this.idDocumentoExample)
            data.append('id_tipo_documento', JSON.stringify(this.id_tipo_documento))
            data.append('user_id', this.userID)
            data.append('archivo', this.file)
            data.append('archivoTraducido', this.fileTraducido)
            this.$http.post('/v1/document-studiante/create', data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'SUBIR';
}
</style>
