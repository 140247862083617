<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <template #modal-header>
      <h5>Cambio de estado</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="ChangeStatusForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Estado"
                    label-for="change-status"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Estado"
                    >
                      <v-select
                        v-model="status_id"
                        name="change-status"
                        label="name"
                        :options="rowsStatus"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="status_id && status_id.name == 'Rechazado'"
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Motivo del rechazo"
                    label-for="change-motivo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Descripción"
                    >
                      <b-form-input
                        id="change-motivo"
                        v-model="motivo"
                        placeholder="descripción"
                        :state="errors.length > 0 ? false:null"
                        name="change-motivo"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, alpha, alphaSpaces,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BForm, BButton, BContainer, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    vSelect,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    idDocumento: {
      type: Array,
      required: true,
      default: () => null,
    },
    urlUpdate: {
      type: String,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      status_id: null,
      value: [],
      options: [],
      rowsStatus: [],
      motivo: null,
      // validation rules
      required,
      integer,
      alpha,
      alphaSpaces,
    }
  },
  mounted() {
    this.getStatus()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getStatus() {
      this.$http.get('/v1/status/get/')
        .then(res => {
          this.rowsStatus = res.data
        })
    },
    save() {
      this.$refs.ChangeStatusForm.validate().then(success => {
        if (success) {
          const data = {
            status_id: this.status_id.id,
            status_array: this.idDocumento,
            motivo: this.motivo,
          }
          this.$http.put(`/v1/${this.urlUpdate}/update/status/`, data).then(res => {//eslint-disable-line
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se ha actualizado satisfactoriamente',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'El registro se ha actualizado.',
              },
            },
            {
              position: 'top-center',
            })
            this.$emit('edit-result')
            this.close()
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            },
            {
              position: 'bottom-right',
            })
          })
        }
      })
    },
  },
}
</script>
