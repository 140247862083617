<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="xl"
  >
    <template #modal-header>
      <h5>Listado de documentos de {{ users.first_name }}</h5>
    </template>
    <div>
      <b-tabs>
        <b-tab
          title="Documentos"
          active
        >
          <b-col cols="12">
            <good-table
              :columns="columns"
              :rows="rows"
              :select-row="true"
              :title-table="`Listado de documentos de ${ users.first_name }`"
              :is_active_create="$can('create', 'documento')"
              :is_active_edit="$can('update', 'documento')"
              :is_active_trash="$can('delete', 'documento')"
              :is_active_trash_all="$can('delete', 'documento')"
              :is_change_status_documento="$can('update', 'documentosestudiante')"
              :is_change_status_documento_traducido="$can('update', 'documentotraducido')"
              :is-loading="isLoading"
              @Crear="Crear"
              @Edit_one="Edit_one"
              @edit="Edit"
              @delete-register="deleteRegister"
              @delete-all="deleteAll"
              @show-archive-document="showArchive"
              @show-archive-traducido="showArchiveTraducido"
              @change-status-document-select="changeStatusDocumentSelect"
              @change-status-document-traducido-select="changeStatusDocumentTraducidoSelect"
              @selection-changed="selectionChanged"
            />
          </b-col>
        </b-tab>
        <b-tab
          title="Papelera"
        >
          <b-col cols="12">
            <good-table
              :columns="columnsDelete"
              :rows="rowsDelete"
              :select-row="true"
              title-table="Tipo de documentos"
              :is_active_trash_back="$can('delete', 'documento')"
              :is_active_trash_all_back="$can('delete', 'documento')"
              @delete-register-back="deleteRegisterBack"
              @delete-all-back="deleteAllBack"
              @show-archive="showArchive"
              @selection-changed="selectionChangedDelete"
            />
          </b-col>
        </b-tab>
      </b-tabs>
      <vue-form
        v-if="modalShowDocumento"
        :modal-show="modalShowDocumento"
        :info-data="infoData"
        :user-i-d="userID"
        :pais-i-d="paisID"
        @create-result="getDocument"
        @edit-result="getDocument"
        @close="closeShowDocumento"
      />
      <show-archive
        :modal-show="modalShowArchive"
        :get-file-path="getFilePath"
        @close="close2"
      />
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
    <vue-status
      v-if="modalChangeStatus"
      :modal-show="modalChangeStatus"
      :url-update="urlUpdate"
      :id-documento="idDocumento"
      @edit-result="getDocument"
      @close="closeChangeTraducidoSelect"
    />
  </b-modal>
</template>

<script>
import {
  BCol, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import Form from '@/components/documents/formDocumentosStudiantesModal.vue'
import ShowArchive from '@/components/documents/ShowArchive.vue'
import ChangeStatus from '@/components/documents/ChangeStatus.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    GoodTable,
    BTabs,
    BTab,
    BButton,
    'vue-form': Form,
    'show-archive': ShowArchive,
    'vue-status': ChangeStatus,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    users: {
      type: Object,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      rowsDelete: [],
      selectRow: null,
      selecction: [],
      selecctionDelete: [],
      modalShow2: false,
      modalShowArchive: false,
      modalShowDocumento: false,
      modalChangeStatus: false,
      userID: 0,
      paisID: 0,
      urlUpdate: null,
      idDocumento: [],
      infoData: {},
      getFilePath: '',
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Documento',
          field: 'archivo_normal',
          tdClass: 'text-center',
        },
        {
          label: 'Estado documento',
          field: 'status.name',
          tdClass: 'text-center',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar estado',
          },
        },
        {
          label: 'Motivo de rechazo de documento',
          field: 'motivo',
          tdClass: 'text-center',
        },
        {
          label: 'Documento traducido',
          field: 'documento_traducido.archivo_traducir',
          tdClass: 'text-center',
        },
        {
          label: 'Estado documento traducido',
          field: 'documento_traducido.status.name',
          tdClass: 'text-center',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar estado',
          },
        },
        {
          label: 'Motivo de rechazo de documento traducido',
          field: 'documento_traducido.motivo',
          tdClass: 'text-center',
        },
      ],
      columnsDelete: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Documento',
          field: 'archivo_normal',
          tdClass: 'text-center',
        },
        {
          label: 'Documento traducido',
          field: 'documento_traducido.archivo_traducir',
          tdClass: 'text-center',
        },
        {
          label: 'Creado',
          field: 'created_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Borrado',
          field: 'deleted_at',
          formatFn: this.formatFn,
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
      ],
    }
  },
  created() {
    this.getDocument()
    this.getDocumentDelete()
  },
  methods: {
    closeShowDocumento() {
      this.modalShowDocumento = false
      this.infoData = {}
    },
    close() {
      this.$emit('close')
    },
    close2() {
      this.modalShowArchive = false
      this.getFilePath = ''
    },
    formatFn(value) {
      if (!value) return ''
      const dateFormat = value
      return moment(dateFormat).format('YYYY-MM-DD HH:mm:ss')
    },
    getDocument() {
      this.isLoading = true
      this.$http.get(`/v1/document-studiante/get/${this.users.id_users}`)
        .then(res => {
          this.isLoading = false
          this.rows = res.data
        })
    },
    getDocumentDelete() {
      this.$http.get(`/v1/document-studiante/get/delete/${this.users.id_users}`)
        .then(res => { this.rowsDelete = res.data })
    },
    Crear() {
      this.modalShowDocumento = true
      this.infoData = {}
      this.userID = this.users.id_users
      this.paisID = this.users.pais_id_universidad
    },
    Edit(val) {
      this.infoData = val
      this.userID = val.user_id
      this.modalShowDocumento = true
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShowDocumento = true
      }
    },
    deleteRegister(val) {
      this.$http.delete(`/v1/document-studiante/delete/${val.id}`).then(res => {//eslint-disable-line
        this.getDocument()
        this.getDocumentDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/document-studiante/delete/', {
          id_documents: this.selecction,
        }).then(res => {//eslint-disable-line
          this.getDocument()
          this.getDocumentDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
    selectionChangedDelete(val) {
      this.selecctionDelete = val.selectedRows
    },
    deleteRegisterBack(val) {
      this.$http.delete(`/v1/document-studiante/delete/restore/${val.id}`).then(res => {//eslint-disable-line
        this.getDocument()
        this.getDocumentDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha Restaurado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha Restaurado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAllBack() {
      if (this.selecctionDelete.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton actualizar estado debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/document-studiante/delete/restoreall', {
          id_documents: this.selecctionDelete,
        }).then(res => {//eslint-disable-line
          this.getDocument()
          this.getDocumentDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registro seleccionado se han restaurado correctamente.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    showArchive(val) {
      let carpeta = ''
      let archivo  = val.archivo //eslint-disable-line
      carpeta = 'document_users'
      this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
      this.modalShowArchive = true
    },
    showArchiveTraducido(val) {
      let carpeta = ''
      carpeta = 'document_users'
      let archivo = val.documento_traducido.archivo //eslint-disable-line
      this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
      this.modalShowArchive = true
    },
    async changeStatusDocumentSelect() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton elimanar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.modalChangeStatus = true
        this.urlUpdate = 'document-studiante'
        this.idDocumento = await this.setIdDocumento(this.selecction)
      }
    },
    async changeStatusDocumentTraducidoSelect() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'Debes selecciónar al menos un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else if (this.validateDocumentsTranslated(this.selecction)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar registros que tengan traducción',
            icon: 'XIcon',
            variant: 'warning',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.modalChangeStatus = true
        this.urlUpdate = 'document-traducido'
        this.idDocumento = await this.setIdDocumentoTraducido(this.selecction)
      }
    },
    validateDocumentsTranslated(val) {
      for (let i = 0; i < val.length; i += 1) {
        if (val[i].documento_traducido == null) {
          return true
        }
      }
      return false
    },
    async setIdDocumento(val) {
      const arrayMap = []
      for (let i = 0; i < val.length; i += 1) {
        arrayMap.push(val[i].id)
      }
      return arrayMap
    },
    async setIdDocumentoTraducido(val) {
      let arrayMap = []
      arrayMap = val.map(row => row.documento_traducido.id)
      return arrayMap
    },
    closeChangeTraducidoSelect() {
      this.modalChangeStatus = false
    },
  },
}
</script>
