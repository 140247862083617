<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="xl"
  >
    <template #modal-header>
      <h5>Listado de acudientes de {{ users.first_name }}</h5>
    </template>
    <div>
      <b-tabs>
        <b-tab
          title="Familiares"
          active
        >
          <b-col cols="12">
            <good-table
              :columns="columns"
              :rows="rows"
              :select-row="true"
              :title-table="`Listado de acudientes de ${users.first_name}`"
              :is_active_create="$can('create', 'familiares')"
              :is_active_edit="$can('update', 'familiares')"
              :is_active_trash="$can('delete', 'familiares')"
              :is_active_trash_all="$can('delete', 'familiares')"
              :is_active_responsable="$can('update', 'familiares')"
              :is-loading="isLoading"
              @Crear="Crear"
              @Edit_one="Edit_one"
              @edit="Edit"
              @delete-register="deleteRegister"
              @delete-all="deleteAll"
              @active-responsable="activeResponsable"
              @selection-changed="selectionChanged"
            />
          </b-col>
        </b-tab>
        <b-tab
          title="Papelera"
        >
          <b-col cols="12">
            <good-table
              :columns="columnsDelete"
              :rows="rowsDelete"
              :select-row="true"
              title-table="Listado de familiares de"
              :is_active_trash_back="$can('delete', 'familiares')"
              :is_active_trash_all_back="$can('delete', 'familiares')"
              @delete-register-back="deleteRegisterBack"
              @delete-all-back="deleteAllBack"
              @selection-changed="selectionChangedDelete"
            />
          </b-col>
        </b-tab>
      </b-tabs>
      <vue-form
        :modal-show="modalShowForm"
        :info-data="infoData"
        :user-i-d="userID"
        @create-result="getFamily"
        @edit-result="getFamily"
        @close="closeFamily"
      />
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BCol, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import Form from '@/views/family/form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    GoodTable,
    BTabs,
    BTab,
    BButton,
    'vue-form': Form,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    users: {
      type: Object,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      rowsDelete: [],
      selectRow: null,
      selecction: [],
      selecctionDelete: [],
      modalShowForm: false,
      userID: 0,
      urlUpdate: null,
      idDocumento: [],
      infoData: {},
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Responsable del contrato',
          field: 'responsable',
          sortable: false,
        },
        {
          label: 'Nombre',
          field: 'fullname',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Cedula',
          field: 'cedula',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Cedula',
          },
        },
        {
          label: 'Celular',
          field: 'celular',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Celular',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Email',
          },
        },
        {
          label: 'Ocupación',
          field: 'ocupacion',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Ocupación',
          },
        },
        {
          label: 'Dirección',
          field: 'direccion',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Dirección',
          },
        },
        {
          label: 'Ciudad',
          field: 'ciudad.name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Ciudad',
          },
        },
      ],
      columnsDelete: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Creado',
          field: 'created_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Borrado',
          field: 'deleted_at',
          formatFn: this.formatFn,
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
      ],
    }
  },
  created() {
    this.getFamily()
    this.getFamilyDelete()
  },
  methods: {
    closeFamily() {
      this.modalShowForm = false
      this.infoData = {}
    },
    close() {
      this.$emit('close')
    },
    formatFn(value) {
      if (!value) return ''
      const dateFormat = value
      return moment(dateFormat).format('YYYY-MM-DD HH:mm:ss')
    },
    getFamily() {
      this.isLoading = true
      this.$http.get(`/v1/family/get/${this.users.id_users}`)
        .then(res => {
          this.isLoading = false
          this.rows = res.data
        })
    },
    getFamilyDelete() {
      this.$http.get(`/v1/family/get/delete/${this.users.id_users}`)
        .then(res => { this.rowsDelete = res.data })
    },
    Crear() {
      this.modalShowForm = true
      this.infoData = {}
      this.userID = this.users.id_users
    },
    Edit(val) {
      this.infoData = val
      this.userID = val.user_id
      this.modalShowForm = true
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShowForm = true
      }
    },
    deleteRegister(val) {
      this.$http.delete(`/v1/family/delete/${val.id}`).then(res => {//eslint-disable-line
        this.getFamily()
        this.getFamilyDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/family/delete/', {
          id_families: this.selecction,
        }).then(res => {//eslint-disable-line
          this.getFamily()
          this.getFamilyDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
    selectionChangedDelete(val) {
      this.selecctionDelete = val.selectedRows
    },
    deleteRegisterBack(val) {
      this.$http.delete(`/v1/family/delete/restore/${val.id}`).then(res => {//eslint-disable-line
        this.getFamily()
        this.getFamilyDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha Restaurado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha Restaurado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAllBack() {
      if (this.selecctionDelete.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton actualizar estado debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/family/delete/restoreall', {
          id_families: this.selecctionDelete,
        }).then(res => {//eslint-disable-line
          this.getFamily()
          this.getFamilyDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registro seleccionado se han restaurado correctamente.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    activeResponsable(val) {
      let responsableCut = 0
      if (val.responsable === null || val.responsable === 0) {
        responsableCut = 1
      } else {
        responsableCut = 0
      }
      this.$http.put(`/v1/family/update/responsable/${val.id}`, {
        responsable: responsableCut,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        this.getFamily()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>
