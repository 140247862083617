<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
    >
      <b-col cols="12">
        <good-table
          ref="gootable"
          title-table="Listado de estudiantes"
          :columns="columns"
          :is-active-create="$can('create', 'users')"
          :is-active-edit="$can('update', 'users')"
          :is-active-document="$can('read', 'documentosestudiante')"
          :is-active-document-select="$can('read', 'documentosestudiante')"
          :is-active-course-all="$can('read', 'users')"
          :is-active-course="$can('read', 'users')"
          :is-active-family="$can('read', 'familiares')"
          :is-active-contrato="$can('read', 'contrato')"
          :is-active-show-data-all="false "
          :is-active-exportar="false"
          :is-active-notificar="false"
          :is-active-notify-all="false"
          @Crear="Crear"
          @EditOne="Edit_one"
          @Edit="Edit"
          @active-course="activeCourse"
          @active-course-checo="activeCourseCheco"
          @ActivateCourseAll="activeCourseAll"
          @activate-course-all="activeCourseUser"
          @ActivateDocument="activateDocument"
          @ActivateFamily="activateFamily"
          @ActivateDocumentSelect="activateDocumentSelect"
          @OpcionContrato="ContratoShow"
          @selection-changed="selectionChanged"
        />
      </b-col>
      <vue-documents
        v-if="modalDocumentos"
        :modal-show="modalDocumentos"
        :users="UsersData"
        @close="closeDocumentos"
      />
      <vue-family
        v-if="modalFamily"
        :modal-show="modalFamily"
        :info-data="infoData"
        :users="UsersData"
        @close="closeFamily"
      />
      <show-archive
        size="xl"
        ancho="750px"
        :modal-show="modalShowArchive"
        :get-file-path="getFilePath"
        :userid="UsersData"
        @close="close2"
      />
      <course-active
        ref="CourseActive"
        :modal-show="modalShowCourse"
        :userid="UsersData"
        @result-save="$refs.gootable.loadItems()"
        @close="closeCourseActive"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BCol, BOverlay,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/RemoteTable.vue'
import IndexFamily from '@/views/family/Index.vue'
import IndexDocumentos from '@/components/documents/IndexDocumentUserModal.vue'
import IndexCourses from '@/views/users/CourseActive.vue'
import ShowArchive from '@/components/documents/ShowArchive.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BOverlay,
    GoodTable,
    'vue-documents': IndexDocumentos,
    'vue-family': IndexFamily,
    'show-archive': ShowArchive,
    'course-active': IndexCourses,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      modalShowArchive: false,
      show: false,
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      searchTerm: null,
      UsersData: {},
      modalDocumentos: false,
      modalFamily: false,
      getFilePath: '',
      modalShowCourse: false,
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Estado',
          field: 'status_user_',
          tdClass: 'text-center',
          width: '160px',
          filterOptions: {
            enabled: true,
            customFilter: false,
            placeholder: 'Selecciona un estado',
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              { value: 'Desactivado', text: 'Desactivado' },
              { value: 'Activado', text: 'Activado' },
            ],
          },
        },
        {
          label: 'Fecha de contrato',
          field: 'fecha_contrato_',
          firstSortType: 'asc',
          width: '190px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: '#Contrato',
          field: 'n_contrato',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Tipo de contrato',
          field: 'tipo_contrato',
          width: '190px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Cuotas',
          field: 'cuotas_pago',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Total a pagar',
          field: 'total_price_',
          width: '140px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Nombres',
          field: 'first_name',
          width: '160px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Apellidos',
          field: 'last_name',
          width: '160px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Ciudad de viaje',
          field: 'viaje_ciudad',
          width: '160px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Universidad',
          field: 'universidad',
          width: '160px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Fecha de viaje',
          field: 'fecha_viaje',
          width: '160px',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
      ],
    }
  },
  methods: {
    closeDocumentos() {
      this.modalDocumentos = false
    },
    closeFamily() {
      this.modalFamily = false
    },
    close() {
      this.modalShow = false
      this.infoData = {}
    },
    close2() {
      this.modalShowArchive = false
      this.getFilePath = ''
    },
    closeCourseActive() {
      this.modalShowCourse = !this.modalShowCourse
    },
    Crear() {
      this.$router.push({
        name: 'apps-users-crear',
      })
    },
    Edit(val) {
      this.$router.push({
        name: 'apps-users-edit',
        params: {
          id: val.id_users,
        },
      })
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$router.push({
          name: 'apps-users-edit',
          params: {
            id: this.selecction['0'].id_users,
          },
        })
      }
    },
    activeCourse(val) {
      this.$http.put(`/v1/users/update/active-course/${val.id_users}`, {
        access_course: val.access_course === 0 ? 1 : 0,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    activeCourseUser(val) {
      this.$refs.CourseActive.selected = []
      this.modalShowCourse = !this.modalShowCourse
      this.UsersData = val
    },
    activeCourseCheco(val) {
      this.$http.put(`/v1/users/update/active-course-checo/${val.id_users}`, {
        access_course_checo: val.access_course_checo === 0 ? 1 : 0,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    activeCourseAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$refs.CourseActive.selected = []
        this.modalShowCourse = !this.modalShowCourse
        this.UsersData = this.selecction['0']
      }
    },
    activateDocument(val) {
      this.UsersData = val
      this.modalDocumentos = true
    },
    activateFamily(val) {
      this.UsersData = val
      this.modalFamily = true
    },
    activateDocumentSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.UsersData = this.selecction['0']
        this.modalDocumentos = true
      }
    },
    ContratoShow(val) {
      this.show = true
      this.$refs.gootable.isLoading = true
      this.UsersData = val
      this.$http.post('/v1/contract-pdf/download/', {
        id: val.id_users,
        font_contract: '12px',
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contrato a imprimir',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El contrato se genero correctamente.',
          },
        },
        {
          position: 'top-center',
        })
        const carpeta = 'contract_user'
        let archivo  = res.data.data //eslint-disable-line
        this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
        this.modalShowArchive = true
        this.show = false
        this.$refs.gootable.isLoading = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
        this.show = false
        this.$refs.gootable.isLoading = false
      })
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
