<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
  >
    <template #modal-header>
      <h5>{{ title }}</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="FamilyForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="family-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="nombre"
                      rules="required|alphaSpaces"
                    >
                      <b-form-input
                        id="universidad-name"
                        v-model="name"
                        placeholder="Nombres"
                        :state="errors.length > 0 ? false:null"
                        name="universidad-name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Apellidos"
                    label-for="family-last_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apellidos"
                      vid="apellidos"
                      rules="required|alphaSpaces"
                    >
                      <b-form-input
                        id="family-last_name"
                        v-model="last_name"
                        placeholder="Apellidos"
                        :state="errors.length > 0 ? false:null"
                        name="family-last_name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Cedula"
                    label-for="family-cedula"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cedula"
                      vid="cedula"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="family-cedula"
                        v-model="cedula"
                        placeholder="cedula"
                        :state="errors.length > 0 ? false:null"
                        name="family-cedula"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Expedición"
                    label-for="family-expedicion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="expedicion"
                      vid="expedicion"
                      rules="required|alphaSpaces"
                    >
                      <b-form-input
                        id="family-expedicion"
                        v-model="expedicion"
                        placeholder="Expedición"
                        :state="errors.length > 0 ? false:null"
                        name="family-expedicion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Dirección"
                    label-for="family-direccion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="direccion"
                      vid="direccion"
                      rules="required"
                    >
                      <b-form-input
                        id="family-direccion"
                        v-model="direccion"
                        placeholder="Dirección"
                        :state="errors.length > 0 ? false:null"
                        name="family-direccion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Celular"
                    label-for="family-celular"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="celular"
                      vid="celular"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="family-celular"
                        v-model="celular"
                        placeholder="Celular"
                        :state="errors.length > 0 ? false:null"
                        name="family-celular"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Teléfono"
                    label-for="family-telefono"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="telefono"
                      vid="telefono"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="family-telefono"
                        v-model="telefono"
                        placeholder="Teléfono"
                        :state="errors.length > 0 ? false:null"
                        name="family-telefono"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Ocupación"
                    label-for="family-ocupacion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ocupacion"
                      vid="ocupacion"
                      rules="required|alphaSpaces"
                    >
                      <b-form-input
                        id="family-ocupacion"
                        v-model="ocupacion"
                        placeholder="Ocupación"
                        :state="errors.length > 0 ? false:null"
                        name="family-ocupacion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="family-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="family-email"
                        v-model="emailFamily"
                        placeholder="Email"
                        :state="errors.length > 0 ? false:null"
                        name="family-email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Genero"
                    label-for="family-gender"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Genero"
                    >
                      <v-select
                        v-model="tipo_gender"
                        name="family-gender"
                        label="name"
                        :options="rowsTypeGender"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group
                    label="País"
                    label-for="family-pais_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Pais"
                    >
                      <v-select
                        v-model="pais_id"
                        name="family-pais_id"
                        label="name"
                        :options="rowsPais"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="family-ciudad_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Departamento"
                    >
                      <v-select
                        v-model="ciudad_id"
                        name="family-ciudad_id"
                        label="name"
                        :options="rowsDepartamento"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group
                    label="Ciudad"
                    label-for="family-municipio_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Ciudad"
                    >
                      <v-select
                        v-model="municipio_id"
                        name="family-municipio_id"
                        label="name"
                        :options="rowsCiudad"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, email,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BForm, BButton, BContainer, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: true,
      default: () => null,
    },
    userID: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      user_id: null,
      value: [],
      options: [],
      idfamily: null,
      name: null,
      last_name: null,
      cedula: null,
      direccion: null,
      celular: null,
      telefono: null,
      responsable: null,
      ocupacion: null,
      emailFamily: null,
      expedicion: null,
      tipo_gender: null,
      municipio_id: null,
      municipio_id_select: null,
      ciudad_id: null,
      ciudad_id_select: null,
      pais_id: null,
      rowsTypeGender: [],
      rowsPais: [],
      rowsDepartamento: [],
      rowsCiudad: [],
      // validation rules
      required,
      integer,
      email,
    }
  },
  computed: {
    title() {
      return this.infoData ? 'Editar' : 'Crear'
    },
  },
  watch: {
    pais_id(val) {
      if (val) {
        this.ciudad_id = null
        this.getDepartamento(val.id)
      }
    },
    ciudad_id(val) {
      if (val) {
        this.municipio_id = null
        this.getCiudad(val.id)
      }
    },
    infoData(val) {
      this.clear()
      if (Object.keys(val).length > 0) {
        this.idfamily = val.id
        this.name = val.name
        this.last_name = val.last_name
        this.cedula = val.cedula
        this.direccion = val.direccion
        this.celular = val.celular
        this.telefono = val.telefono
        this.ocupacion = val.ocupacion
        this.email = val.email
        this.expedicion = val.expedicion
        this.emailFamily = val.email
        this.tipo_gender = val.gender
        this.pais_id = val.pais
        this.ciudad_id_select = val.ciudad
        this.municipio_id_select = val.municipio
      }
    },
  },
  created() {
    this.getTypeGender()
    this.getPais()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clear() {
      this.idfamily = null
      this.name = null
      this.last_name = null
      this.cedula = null
      this.direccion = null
      this.celular = null
      this.telefono = null
      this.responsable = null
      this.ocupacion = null
      this.email = null
      this.expedicion = null
      this.tipo_gender = null
      this.municipio_id = null
      this.ciudad_id = null
      this.pais_id = null
      this.emailFamily = null
      this.municipio_id_select = null
      this.ciudad_id_select = null
    },
    getTypeGender() {
      this.isLoading = true
      this.$http.get('/v1/tipo-gender/get/')
        .then(res => {
          this.isLoading = false
          this.rowsTypeGender = res.data
        })
    },
    getPais() {
      this.isLoading = true
      this.$http.get('/v1/config-pais/get/status')
        .then(res => {
          this.isLoading = false
          this.rowsPais = res.data
        })
    },
    getDepartamento(id) {
      this.isLoading = true
      this.$http.get(`/v1/config-ciudad/get/${id}`)
        .then(res => {
          this.isLoading = false
          this.rowsDepartamento = res.data
          if (this.ciudad_id_select) {
            this.ciudad_id = this.ciudad_id_select
          }
        })
    },
    getCiudad(id) {
      this.isLoading = true
      this.$http.get(`/v1/config-municipio/get/${id}`)
        .then(res => {
          this.isLoading = false
          this.rowsCiudad = res.data
          if (this.municipio_id_select) {
            this.municipio_id = this.municipio_id_select
          }
        })
    },
    save() {
      this.$refs.FamilyForm.validate().then(success => {
        if (success) {
          if (Object.keys(this.infoData).length > 0) {
            const data = {
              user_id: this.userID,
              name: this.name,
              last_name: this.last_name,
              cedula: this.cedula,
              direccion: this.direccion,
              celular: this.celular,
              telefono: this.telefono,
              responsable: this.responsable,
              ocupacion: this.ocupacion,
              email: this.emailFamily,
              expedicion: this.expedicion,
              tipo_gender: this.tipo_gender.id,
              municipio_id: this.municipio_id.id,
              ciudad_id: this.ciudad_id.id,
              pais_id: this.pais_id.id,
            }
            this.$http.put(`/v1/family/update/${this.idfamily}`, data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            const data = {
              user_id: this.userID,
              name: this.name,
              last_name: this.last_name,
              cedula: this.cedula,
              direccion: this.direccion,
              celular: this.celular,
              telefono: this.telefono,
              responsable: this.responsable,
              ocupacion: this.ocupacion,
              email: this.emailFamily,
              expedicion: this.expedicion,
              tipo_gender: this.tipo_gender.id,
              municipio_id: this.municipio_id.id,
              ciudad_id: this.ciudad_id.id,
              pais_id: this.pais_id.id,
            }
            this.$http.post('/v1/family/create', data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'SUBIR';
}
</style>
