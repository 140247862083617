<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
  >
    <template #modal-header>
      <h5>Activación de cursos</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <b-table-simple
            :sticky-header="true"
            :no-border-collapse="true"
            hover
            caption-top
            responsive
            class="rounded-bottom mb-0"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>
                  Listado de cursos
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>Nombre</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(course, key) in courses"
                :key="key"
              >
                <b-td>
                  <b-form-checkbox-group
                    v-model="selected"
                    name="flavour-2"
                    class="demo-inline-spacing"
                  >
                    <b-form-checkbox
                      :value="course.id"
                      plain
                    >
                      {{ course.label }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td
                  colspan="7"
                  variant="secondary"
                  class="text-right"
                >
                  Totat: {{ courses.length }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BThead, BTr, BTh, BTd, BTbody, BTfoot, BFormCheckbox, BFormCheckboxGroup,
  BRow, BCol, BButton, BContainer, BTableSimple,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTableSimple,
    BContainer,
    BRow,
    BCol,
    BButton,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    userid: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      courses: [
        {
          id: 'access_course',
          label: 'Ruso',
        },
        {
          id: 'access_course_checo',
          label: 'Checo',
        },
        {
          id: 'access_course_english',
          label: 'Inglés',
        },
      ],
      selected: [],
    }
  },
  watch: {
    userid(oldValue) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const property in oldValue) {
        if (property === 'access_course_checo' || property === 'access_course' || property === 'access_course_english') {
          if (oldValue[property]) {
            this.selected.push(property)
          }
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.$http.put('/v1/users/update/active-course/', {
        courses: this.selected,
        users_id: this.userid.id_users,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('result-save')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>
