var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":"Login","no-close-on-esc":"","no-close-on-backdrop":"","centered":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"FamilyForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"family-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"nombre","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"universidad-name","placeholder":"Nombres","state":errors.length > 0 ? false:null,"name":"universidad-name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Apellidos","label-for":"family-last_name"}},[_c('validation-provider',{attrs:{"name":"Apellidos","vid":"apellidos","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-last_name","placeholder":"Apellidos","state":errors.length > 0 ? false:null,"name":"family-last_name"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Cedula","label-for":"family-cedula"}},[_c('validation-provider',{attrs:{"name":"cedula","vid":"cedula","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-cedula","placeholder":"cedula","state":errors.length > 0 ? false:null,"name":"family-cedula"},model:{value:(_vm.cedula),callback:function ($$v) {_vm.cedula=$$v},expression:"cedula"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Expedición","label-for":"family-expedicion"}},[_c('validation-provider',{attrs:{"name":"expedicion","vid":"expedicion","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-expedicion","placeholder":"Expedición","state":errors.length > 0 ? false:null,"name":"family-expedicion"},model:{value:(_vm.expedicion),callback:function ($$v) {_vm.expedicion=$$v},expression:"expedicion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Dirección","label-for":"family-direccion"}},[_c('validation-provider',{attrs:{"name":"direccion","vid":"direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-direccion","placeholder":"Dirección","state":errors.length > 0 ? false:null,"name":"family-direccion"},model:{value:(_vm.direccion),callback:function ($$v) {_vm.direccion=$$v},expression:"direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Celular","label-for":"family-celular"}},[_c('validation-provider',{attrs:{"name":"celular","vid":"celular","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-celular","placeholder":"Celular","state":errors.length > 0 ? false:null,"name":"family-celular"},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"family-telefono"}},[_c('validation-provider',{attrs:{"name":"telefono","vid":"telefono","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-telefono","placeholder":"Teléfono","state":errors.length > 0 ? false:null,"name":"family-telefono"},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Ocupación","label-for":"family-ocupacion"}},[_c('validation-provider',{attrs:{"name":"ocupacion","vid":"ocupacion","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-ocupacion","placeholder":"Ocupación","state":errors.length > 0 ? false:null,"name":"family-ocupacion"},model:{value:(_vm.ocupacion),callback:function ($$v) {_vm.ocupacion=$$v},expression:"ocupacion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"family-email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family-email","placeholder":"Email","state":errors.length > 0 ? false:null,"name":"family-email"},model:{value:(_vm.emailFamily),callback:function ($$v) {_vm.emailFamily=$$v},expression:"emailFamily"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Genero","label-for":"family-gender"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Genero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"family-gender","label":"name","options":_vm.rowsTypeGender,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_gender),callback:function ($$v) {_vm.tipo_gender=$$v},expression:"tipo_gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"País","label-for":"family-pais_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Pais"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"family-pais_id","label":"name","options":_vm.rowsPais,"placeholder":"Seleccione una opción"},model:{value:(_vm.pais_id),callback:function ($$v) {_vm.pais_id=$$v},expression:"pais_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Departamento","label-for":"family-ciudad_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Departamento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"family-ciudad_id","label":"name","options":_vm.rowsDepartamento,"placeholder":"Seleccione una opción"},model:{value:(_vm.ciudad_id),callback:function ($$v) {_vm.ciudad_id=$$v},expression:"ciudad_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Ciudad","label-for":"family-municipio_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ciudad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"family-municipio_id","label":"name","options":_vm.rowsCiudad,"placeholder":"Seleccione una opción"},model:{value:(_vm.municipio_id),callback:function ($$v) {_vm.municipio_id=$$v},expression:"municipio_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }