<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    :size="size"
  >
    <template #modal-header>
      <h5>Archivo</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="8"
        >
          <iframe
            :height="ancho"
            width="100%"
            :src="`${getFilePath}`"
          />
        </b-col>
        <b-col
          cols="4"
        >
          <h5>Opciones</h5>
          <hr>
          <b-form
            class="mt-2"
          >
            <b-col
              cols="12"
              sm="12"
            >
              <b-form-group
                label="Tamaño de letra del contrato"
                label-for="font-contract"
              >
                <v-select
                  v-model="font_contract"
                  name="font-contract"
                  label="name"
                  :options="rowsConfig"
                  placeholder="Seleccione una opción"
                />
              </b-form-group>
            </b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-right ml-1"
              @click="UpdateContract"
            >
              Aplicar cambios
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BButton, BContainer, BFormGroup, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    size: {
      type: String,
      required: false,
      default: () => 'sm',
    },
    ancho: {
      type: String,
      required: false,
      default: () => '400px',
    },
    getFilePath: {
      type: String,
      required: true,
      default: () => null,
    },
    userid: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      tipo_estudio_id: null,
      is_active: 0,
      is_translator: 0,
      font_contract: {
        id: 12,
        name: '12px',
      },
      id: null,
      value: [],
      options: [],
      rowsTypeStudy: null,
      permissionsByRole: null,
      file: null,
      rowsConfig: [],
    }
  },
  mounted() {
    this.getSizeFont()
  },
  methods: {
    UpdateContract() {
      this.$http.post('/v1/contract-pdf/download/', {
        id: this.userid.id_users,
        font_contract: this.font_contract.name,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Letra',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El contrato se le actualizo la fuente.',
          },
        },
        {
          position: 'top-center',
        })
        const carpeta = 'contract_user'
        let archivo  = res.data.data //eslint-disable-line
        this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    getSizeFont() {
      for (let i = 9; i <= 21; i++) { //eslint-disable-line
        this.rowsConfig.push(
          {
            id: i,
            name: `${i}px`,
          },
        )
      }
    },
    close() {
      this.clear()
      this.$emit('close')
    },
    clear() {
      this.name = null
      this.description = null
      this.id = null
      this.getFilePath = null
      this.file = null
      this.font_contract = {
        id: 12,
        name: '12px',
      }
    },
  },
}
</script>
