<template>
  <b-card :title="titleTable">
    <vue-good-table
      mode="remote"
      :is-loading.sync="isLoadingActive"
      row-style-class="text-sm"
      :pagination-options="{
        enabled: true,
        position: 'both',
      }"
      :select-options="{
        enabled: selectRow,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'registro seleccionado',
        clearSelectionText: 'Limpiar',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :total-rows="totalRecords"
      :rows="rows"
      :columns="columns"
      :line-numbers="true"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="emptystate">
        No existen registros
      </div>
      <div slot="table-actions">
        <b-button-toolbar
          justify
        >
          <p class="mx-1">
            <strong>Acciones:</strong>
          </p>
          <b-button-group>
            <b-button
              v-if="isActiveCreate"
              v-b-tooltip.hover.top="'Crear usuario'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('Crear')"
            >
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-button
              v-if="isActiveEdit"
              v-b-tooltip.hover.top="'Editar usuario'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('EditOne')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="isActiveDocumentSelect"
              v-b-tooltip.hover.top="'Ver documentos de usuario'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('ActivateDocumentSelect')"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              v-if="isActiveMesssage"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('SendMessage')"
            >
              <feather-icon icon="MessageCircleIcon" />
            </b-button>
            <b-button
              v-if="isActiveTrashAll"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('DeleteAll')"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-if="isActiveTrashAllBack"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('DeleteAllBack')"
            >
              <feather-icon icon="ThumbsUpIcon" />
            </b-button>
            <b-button
              v-if="isActiveCourseAll"
              v-b-tooltip.hover.top="'Activar cursos'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('ActivateCourseAll')"
            >
              <feather-icon icon="BookmarkIcon" />
            </b-button>
            <b-button
              v-if="isActiveNotifyAll"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('SendNotifyAll')"
            >
              <feather-icon icon="BellIcon" />
            </b-button>
            <b-button
              v-if="isActiveShowDataAll"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('DocumentShow')"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="isChangeStatusDocumento"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('ChangeStatusDocumentSelect')"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              v-if="isChangeStatusDocumentoTraducido"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('ChangeStatusDocumentTraductorSelect')"
            >
              <feather-icon icon="BookIcon" />
            </b-button>
            <b-button
              v-if="isActiveExportar"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('Exportar')"
            >
              <feather-icon icon="ArrowDownCircleIcon" />
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>
      <template slot="table-column" slot-scope="props">
          <span class="text-sm-1">
              {{ props.column.label }}
          </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'fullname'"
          class="text-nowrap"
        >
          <b-avatar
            :src="setAvatar(props.row.avatar)"
          />
          {{ props.row.first_name }} {{ props.row.last_name }}
        </span>
        <span v-if="props.column.field === 'fecha_contrato_'">
          <template v-if="props.row.fecha_contrato">
            {{ customFormatter(props.row.fecha_contrato) }}
          </template>
        </span>
        <span v-else-if="props.column.field === 'total_price_'">
          <template v-if="props.row.total_price">
            ${{ formatNumber(props.row.total_price) }}
          </template>
        </span>
        <span v-else-if="props.column.field === 'status_user_'">
          <b-badge
            pill
            :variant="getStatus(props.row.status_user)"
          >
            {{ props.row.status_user }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <template v-if="props.column.field === 'action'">
          <span>
            <b-button
              v-if="isActiveEdit"
              v-b-tooltip.hover.top="'Editar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="$emit('Edit', props.row)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              v-if="isActiveTrash"
              v-b-tooltip.hover.top="'Eliminar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="$emit('Delete', props.row)"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              v-if="isActiveTrashBack"
              v-b-tooltip.hover.top="'Restaurar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="DeleteBack(props.row)"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              v-if="isActiveDetail"
              v-b-tooltip.hover.top="'Ver registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon "
              @click="Detail(props.row)"
            >
              <feather-icon icon="LayersIcon" />
            </b-button>
            <b-button
              v-if="isActiveCourse"
              v-b-tooltip.hover.top="'Activar curso'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="ActivateCourse(props.row)"
            >
              <feather-icon icon="BookmarkIcon" />
            </b-button>
            <b-button
              v-if="isActiveFamily"
              v-b-tooltip.hover.top="'Acudientes'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('ActivateFamily', props.row)"
            >
              <feather-icon icon="UsersIcon" />
            </b-button>
            <b-button
              v-if="isActiveDocument"
              v-b-tooltip.hover.top="'Documentos del estudiante'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('ActivateDocument', props.row)"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              v-if="isActiveContrato"
              v-b-tooltip.hover.top="'Generar contrato'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('OpcionContrato', props.row)"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <b-button
              v-if="isActiveNotificar"
              v-b-tooltip.hover.top="'Notificaciones'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="SendNotify(props.row)"
            >
              <feather-icon icon="BellIcon" />
            </b-button>
          </span>
          <div class="display-1">
            <template v-if="'responsable' in props.row">
              <b-button
                v-if="props.row.responsable == 0 || props.row.responsable == null"
                v-b-tooltip.hover.top="'Toca para que sea el responsable'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="activeResponsable(props.row)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                v-else
                v-b-tooltip.hover.top="'Toca para quitar responsable'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="activeResponsable(props.row)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </template>
          </div>
        </template>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-top"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap b-b">
          <div class="d-flex align-items-center mb-02 mt-1 mr-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registro </span>
          </div>
          <div>
            <b-pagination
              v-model="valuePagination"
              :value="valuePagination"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0 mr-1"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap b-b">
          <div class="d-flex align-items-center mb-02 mt-1 mr-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registro </span>
          </div>
          <div>
            <b-pagination
              v-model="valuePagination"
              :value="valuePagination"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0 mr-1"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BButtonToolbar, BButtonGroup, BButton, BTooltip, VBTooltip, BAvatar, BBadge, BFormSelect, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { FormatDate } from '@core/utils/filter'
import moment from 'moment'
/* eslint-disable */
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BCard,
    BBadge,
    BFormSelect,
    BTooltip,
    BPagination,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
  },
  props: {
    url:  {
      type: String,
      required: false,
      default: () => '/v1/users/all/1/',
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    titleTable: {
      type: String,
      required: false,
      default: () => '',
    },
    selectRow: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    isActiveCreate: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveEdit: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveDocumentSelect: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveExportar: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveMesssage: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveCourseAll: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveNotifyAll: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveShowDataAll: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveTrashAll: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveTrashAllBack: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isChangeStatusDocumento: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isChangeStatusDocumentoTraducido: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveEdit: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveTrash: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveTrashBack: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveDetail: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveCourse: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveFamily: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveContrato: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveNotificar: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveDocument: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup() {
    return {
      FormatDate,
    }
  },
  data() {
    return {
      pageLength: 5,
      valuePagination: 1,
      isLoading: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    isLoadingActive: {
      get() {
        return this.isLoading
      },
      set(val) {
        return val
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'primary',
        Professional : 'success',
        Rejected     : 'danger',
        Resigned     : 'warning',
        Applied      : 'info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    customFormatter(date) {
      return moment(date).add(5, 'hours').format('LL')
    },
    getStatus(value) {
      const statusColor = {
        /* eslint-disable key-spacing */
        Activado      : 'success',
        Desactivado : 'danger',
        Bloqueado     : 'danger',
        Pendiente     : 'warning',
        Revisando      : 'warning',
        Rechazado      : 'danger',
        Aprobado      : 'success',
        Archivado      : 'warning',
        Eliminado      : 'danger',
        /* eslint-enable key-spacing */
      }
      return statusColor[value]
    },
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(value)
    },
    selectionChanged(params) {
      this.$emit('selection-changed', params)
    },
    activeCourse(val) {
      this.$emit('active-course', val)
    },
    ActivateCourse(val) {
      this.$emit('activate-course-all', val)
    },
    activeCourseCheco(val) {
      this.$emit('active-course-checo', val)
    },
    setAvatar(value) {
      if (value != null) {
        return `${process.env.VUE_APP_URL_API}/avatar/${value}`
      } 
      return `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`
    },
    updateParams(newProps) {
      // eslint-disable-next-line prefer-object-spread
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true
      this.$http.get(`${this.url}${this.serverParams.page}/${this.serverParams.perPage}`, {
        params: {
          sort: this.serverParams.sort[0].field,
          order: this.serverParams.sort[0].type,
          ...this.serverParams.columnFilters
        },
      })
        .then(res => {
          this.isLoading = false
          this.totalRecords = res.data.total
          this.rows = res.data.data
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.text-sm {
  font-size: 14px;
}
.text-sm-1 {
  font-size: 15px;
}

.dropdown-menu {
  max-height: 170px;
  overflow: auto;
}
</style>
